import './rvslider';

var elementsToShow = document.querySelectorAll('.show-on-scroll');


$(document).ready(function() {

    $('.menu.mobile a:not(.open__menu)').on('click', function() {
        if ($('.menu.mobile').hasClass('opened')) {
            $('.menu.mobile').removeClass('opened');
        }
    })

    $('.recipe').each(function() {
        $(this).addClass('show-on-scroll');
    });
    elementsToShow = document.querySelectorAll('.show-on-scroll');

    $(".rvs-container").rvslider();

    $('footer').hide();
    $('.container').hide();

    

    $.getJSON("/assets/recipes.json", function(data) {    

        let container = $('.recipes__container');

        let r = 1;

        $.each(data, function(key, val) {

            

            let title = $('<div class="block__title"></div>');
            title.append('<h1>' + key + '</h1>');
            title.append('<span>Come li faceva lei</span>');

            container.append(title);

            let recipesContainer = $('<div class="recipes"></div>');

            let i = 1;
            $.each(val, function(key, recipe) {

                

                if (recipe.title.length > 0) {

                    let orientation = 'left';
                    if (i % 2 == 0) orientation = 'right';
                    i++;

                    let recipeContainer = $('<a href="javascript:void(0)" id="' + r + '" class="recipe show-on-scroll ' + orientation + '"></a>');

                    recipeContainer.append('<div class="spacer"></div>');

                    let recipeImage = $('<div class="recipe__image"></div>');
                    let recipeImageBox = $('<div class="recipe__image__box"></div>');

                    let imageUrl = recipe.title.toLowerCase();
                    imageUrl = imageUrl.replaceAll(' ', '-');
                    imageUrl = imageUrl.replaceAll('’', '');
                    imageUrl = imageUrl.replaceAll('\'', '');
                    imageUrl = imageUrl.replaceAll('ù', 'u');
                    imageUrl = imageUrl.replaceAll('à', 'a');
                    imageUrl += ".jpg";

                    

                    let recipeImageBoxContainer = $('<div class="recipe__image__box__container" style="background-image: url(/assets/recipes/' + imageUrl + ');"></div>');

                    recipeImageBox.append(recipeImageBoxContainer);
                    recipeImage.append(recipeImageBox);

                    recipeContainer.append(recipeImage);
                    recipeContainer.append('<span class="price">' + recipe.price.toFixed(2) + '<br /><small>euri</small></span>');
                    recipeContainer.append('<h1>' + recipe.title + '</h1>');
                    recipeContainer.append('<h5>' + recipe.subtitle + '</h5>');
                    recipeContainer.append('<span class="info">' + recipe.info + '</span>');

                    let hidedInfos = $('<div class="hided__infos"></div>');
                    hidedInfos.append('<h2>Ingredienti</h2><p class="main__description">' + recipe.ingredients + '</p>');
                    
                    let ingredients = $('<div class="ingredients"></div>');
                    
                    if (recipe.tracce_di.length > 0) ingredients.append('<div><h5>Potrebbe contenere tracce di</h5><p>' + recipe.tracce_di + '</p></div>');
        
                    
                    if (recipe.nutritional.length > 0) ingredients.append('<div><h5>Valori Nutrizionali</h5><p>' + recipe.nutritional + '</p></div>');
                    
                    hidedInfos.append(ingredients);

                    recipeContainer.append(hidedInfos);



                    let recipeBox = $('<div class="recipe__box"></div>');

                    let closeButton = $('<button class="close" data-recipe="' + r + '">X</div>');
                    recipeBox.append(closeButton)
                    recipeBox.append(recipeContainer);

                    recipeBox.on('click', function() {

                        let rec = $(this);

                        let toggleElements = ['.credits', '.block__title', '.block', '.rvs-container', '.recipe__box', '.places'];

                        for (var e = 0; e < toggleElements.length; e++) {
                            let el = $(toggleElements[e]);
                            if (rec.hasClass("infos")) {
                                el.show();
                            } else {
                                el.hide();
                            }
                        }

                        if (rec.hasClass("infos")) {
                            rec.removeClass('infos');

                            $([document.documentElement, document.body]).animate({
                                scrollTop: rec.offset().top
                            }, 0);

                        } else {
                            rec.addClass('infos');
                            rec.show();
                            $([document.documentElement, document.body]).animate({
                                scrollTop: 0
                            }, 0);
                        }

                    });

                    recipesContainer.append(recipeBox);

                    r++;
                }
            });

            container.append(recipesContainer);

            elementsToShow = document.querySelectorAll('.show-on-scroll');
        });

        setTimeout(() => {
            $('footer').show();
            $('.loader').addClass('disappear');
            $('.container').show();
        }, 2000);
    });

    $('button.close').on('click', function() {
        $(this).parent().fadeOut();
    });

    $('.open__menu').on('click', function() {
        $('.menu.mobile').toggleClass('opened');
    });

});

$(window).scroll(function() {
    let video = $('.rvs-item-container');
    let el = video;
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    let rect = el.getBoundingClientRect();
    const limit = window.innerHeight - 210;
    
    if (rect.top <= -limit) {
        video.find('.rvs-item-stage').css('opacity', 0);
        video.find('.rvs-cta').show();
        $('header').addClass('sticky');
    } else {
        video.find('.rvs-item-stage').css('opacity', 1);
        video.find('.rvs-cta').hide();
        $('header').removeClass('sticky');
    }
});

// Detect request animation frame
var scroll = window.requestAnimationFrame ||
    // IE Fallback
    function(callback) { window.setTimeout(callback, 1000 / 60) };

function loop() {

    elementsToShow.forEach(function(element) {
        if (isElementInViewport(element)) {
            element.classList.add('is-visible');
        } else {
            element.classList.remove('is-visible');
        }
    });

    scroll(loop);
}

// Call the loop for the first time
loop();

// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
    // special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }
    var rect = el.getBoundingClientRect();
    return (
        (rect.top <= 0 &&
            rect.bottom >= 0) ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
        (rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
}